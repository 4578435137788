.modal-box {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 400;
	box-shadow: 24;
	padding: 2.5rem;
}

.movie-poster {
	margin: 2rem 0;
	height: 20rem;
}

.modal-buttons {
	display: flex;
	justify-content: center;
	margin-top: 2rem;
}
